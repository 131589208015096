<template>
  <div>
    <div class="bg-color"><HeaderStats /></div>
    <CWrapper>
      <!--Section: Block Content-->
      <section class="" style="margin-top: 8rem">
        <div class="row m-lg-5 gap-5">
          <!--Grid column--> 
          <div class="display-inline" v-if="this.status > 0" > <a  @click="removeFromCart()" style=" cursor:pointer; background: gainsboro; padding: 1rem; border-radius: 100%;" class="text-black"  title="Remove"> X</a></div>
          <div class="col-lg-7 shadow rounded" v-if="plan_name">
           
            <!-- Card -->
            <div class="mb-3">
              <div class="pt-4 wish-list">
                <h5 class="mb-4">Cart</h5>

                <div class="row mb-4">
                  <div class="col-md-5 col-lg-3 col-xl-3">
                    <div
                      class="view zoom overlay z-depth-1 rounded mb-3 mb-md-0"
                    ></div>
                  </div>
                  <div>
                    <table class="table">
                      <tr>
                        <th>Plan Name</th>
                        <th>Plan Period</th>
                        <th>Plan Price</th>
                      </tr>
                      <tr>
                        <td>{{ plan_name }} Plan</td>
                        <td>{{ plan_time }} {{ plan_period }}</td>
                        <td>₹ {{ plan_price }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <hr class="mb-4" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 shadow rounded" v-if="!plan_name">
            <h5 class="p-5">Your cart is empty</h5>
          </div>
          <!--Grid column-->
          <div class="col-lg-4 shadow rounded" v-if="plan_price">
            <!-- Card -->
            <div class="mb-3">
              <div class="pt-4">
                <h5 class="mb-3">Bill Amount Detail</h5>
                <ul class="list-group list-group-flush">
                  <!-- <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                      border-0
                      px-0
                      pb-0
                    "
                  >
                    Sub Total
                    <span>₹ {{ plan_price }}</span>
                  </li>
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                      px-0
                    "
                  >
                    GST Taxable Amount (18%)
                    <span>₹ {{ ((18 / 100) * plan_price).toFixed(2) }}</span>
                  </li> -->
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      align-items-center
                      border-0
                      px-0
                      mb-3
                    "
                  >
                    <div>
                      <strong>Total Amount </strong>
                      <strong>
                        <p class="mb-0">(Including Tax)</p>
                      </strong>
                    </div>

                    <span
                      ><strong>
                        ₹
                        {{
                          (planpricetotal =plan_price )
                        }}
                      </strong></span
                    >
                  </li>
                </ul>
                <b-button
                  v-b-modal.modal-prevent-closing
                  style="
                    width: 135px;
                    background-color: #1065b7;
                    text-align: center;
                    font-weight: 800;
                    padding: 11px 0px;
                    color: white;
                    font-size: 12px;
                    display: inline-block;
                    text-decoration: none;
                  "
                  >checkout</b-button
                >
              </div>
            </div>
            <div class="mt-3">
              <b-modal
                id="modal-prevent-closing"
                ref="modal"
                title=" Billing Information "
                hide-footer="true"
              >
              <!-- action="https://secure.payu.in/_payment" -->
                <form method="post"  action="https://secure.payu.in/_payment">
                  <CRow>
                    <CCol sm="6">
                      <label for="Name"> Name</label>
                      <b-form-input
                        type="text"
                        name="firstname"
                        id="firstname"
                        v-model="firstname" readonly
                      >
                      </b-form-input>
                    </CCol>
                    <CCol sm="6">
                      <label for="">Email</label>
                      <b-form-input
                        type="text"
                        name="email"
                        id="email"
                        v-model="useremail" readonly
                      >
                      </b-form-input>
                    </CCol>
                  </CRow>
                  <br />
                  <CRow>
                    <CCol sm="12">
                      <label for="Name">
                        Country <span style="color: red">*</span></label
                      >
                      <b-form-input
                        type="text"
                        name="country"
                        id="country"
                        v-model="comp.country"
                        required
                      >
                      </b-form-input>
                    </CCol>
                  </CRow>
                  <br />
                  <CRow>
                    <CCol sm="12">
                      <label for="Name">
                        Address <span style="color: red">*</span></label
                      >
                      <b-form-input
                        type="text"
                        name="address1"
                        id="address"
                        v-model="comp.address"
                        required
                      >
                      </b-form-input>
                    </CCol>
                  </CRow>

                  <br />
                  <CRow>
                    <CCol sm="6">
                      <label for="Name">
                        Postal Code <span style="color: red">*</span></label
                      >
                      <b-form-input
                        type="number"
                        name="zipcode"
                        id="postalcode"
                        v-model="comp.postalcode"
                        required
                      >
                      </b-form-input>
                    </CCol>
                    <CCol sm="6">
                      <label for=""
                        >State <span style="color: red">*</span></label
                      >
                      <b-form-input
                        type="text"
                        name="state"
                        id="state"
                        v-model="comp.state"
                        required
                      >
                      </b-form-input>
                    </CCol>
                  </CRow>
                  <br />
                  <CRow>
                    <CCol sm="6">
                      <label for="">Company Name</label>
                      <b-form-input
                        type="text"
                        name="udf123"
                        v-model="comp.companyname"
                      >
                      </b-form-input>
                    </CCol>
                    <CCol sm="6">
                      <label for="">GSTIN </label>
                      <b-form-input
                        type="text"
                        name="udf223"
                        v-model="comp.gstnumber"
                        placeholder="04AABCU9603R1ZV"
                      >
                      </b-form-input>
                    </CCol>
                  </CRow>
                  <input type="hidden" name="key" v-model="merchantKey" />
                  <input type="hidden" name="hash" v-model="hash" />
                  <input type="hidden" name="txnid" v-model="txnid" />
                  <input type="hidden" name="surl" v-model="surl" />
                  <input type="hidden" name="furl" v-model="furl" />
                  <input type="hidden" name="service_provider" value="" />
                  <input type="hidden" name="amount" v-model="planpricetotal" />
                  <input
                    type="hidden"
                    name="productinfo"
                    v-model="productinfo"
                  />
                  <br />
                  <input
                    type="hidden"
                    name="phone"
                    v-model="userdetect.user_id12"
                  />
                  <br />

                  <button
                    style="
                      width: 135px;
                      background-color: #1065b7;
                      text-align: center;
                      font-weight: 800;
                      padding: 11px 0px;
                      color: white;
                      font-size: 12px;
                      display: inline-block;
                      text-decoration: none;
                    "
                    @click="companydetail()"
                  >
                    Pay Now
                  </button>
                </form>
              </b-modal>
            </div>
            <!-- Card -->

            <!-- Card -->
            <div class="mb-3">
              <div class="pt-4">
                <div class="collapse" id="collapseExample">
                  <div class="mt-3">
                    <div class="md-form md-outline mb-0">
                      <input
                        type="text"
                        id="discount-code"
                        class="form-control font-weight-light"
                        placeholder="Enter discount code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card -->
          </div>
          <!--Grid column-->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Block Content-->
    </CWrapper>
    <Footer />
  </div>
</template>

<script>
import HeaderStats from "../../components/Headers/HeaderStats";
import Footer from "../../components/Footers/Footer";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      comp: {
        companyname: null,
        gstnumber: null,
        user_id_comp: null,
        postalcode: null,
        state: null,
        address: null,
        country: null,
      },
      regTest_field: false,
      hash: null,
      amount: null,
      txnid: null,
      planpricetotal: null,
      productinfo: null,
      surl: null,
      furl: null,
      service_provider: null,
      firstname: null,
      useremail: null,
      merchantKey: null,
      step: 0,
      plansdetails: [],
      userdetect: {
        user_id12: null,
        planprice: null,
        planname: null,
        pram: null,
        plan_id: null,
      },
      plan_id: null,
      plan_name: null,
      plan_time: null,
      plan_period: null,
      plan_price: null,
      status:null,
      cartdetail:{
        iduser:null,
      }
    };
  },
  name: "Dashboard",
  components: {
    HeaderStats,
    Footer,
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.addProductToCart();
      this.getUserplan();
    }
  },
  methods: {
    ...mapActions([
      "getUserplanlist",
      "addToCart12",
      "PayuPaymentDetail",
      "detailUpdate",
      "getUserplanlistCheck","removeFromCartList",
    ]),

    getUserplan() {
      this.userdetect.user_id12 = localStorage.getItem("user");
      
      this.getUserplanlistCheck(this.userdetect).then((res) => {
        this.plansdetails = res.data.data;
        console.log(this.plansdetails);
        if (this.plansdetails == 1) {
          let message = "";
          let title =
            "Already This Plan Is Active ";
          let type = "info";
          this.$alert(message, title, type).then(() => {
            window.location.href = "/";
          });
        } else {
        }
      });
    },
  paymentInfoGet() {
      this.userdetect.user_id12=localStorage.getItem("user");
      this.userdetect.planname=this.plan_name
      this.userdetect.planprice=this.plan_price
      this.PayuPaymentDetail(this.userdetect).then((res) => {
        this.hash= res.data.data.hash
        this.amount= res.data.data.posteddata.amount
        this.txnid= res.data.data.posteddata.txnid
        this.firstname= res.data.data.posteddata.firstname
        this.useremail= res.data.data.posteddata.email
        this.merchantKey= res.data.data.posteddata.key
        this.service_provider= res.data.data.posteddata.service_provider
        this.surl= res.data.data.posteddata.surl
        this.furl= res.data.data.posteddata.furl
        this.productinfo= res.data.data.posteddata.productinfo
        this.comp.companyname= res.data.data.posteddata.udf123
        this.comp.gstnumber= res.data.data.posteddata.udf223
        
      });
    },
     companydetail(){
      this.comp.user_id_comp=localStorage.getItem("user");
      this.detailUpdate(this.comp).then((res) => {
        alert(res)
      })
    },
    addProductToCart() {
      let user = localStorage.getItem("user");
      this.addToCart12({ id: this.$route.params.id, user_id: user }).then(
        (response) => {
          this.plan_id = response.data.data.id;
          this.plan_name = response.data.data.plan_name;
          this.plan_time = response.data.data.plan_period;
          this.plan_period = response.data.data.plan_period_type;
          this.plan_price = response.data.data.plan_price;
          this.status=response.data.data.id;
          this.userdetect.plan_id= response.data.data.id;
              this. paymentInfoGet();
        }
      );
    },
    removeFromCart() {
      this.cartdetail.iduser = localStorage.getItem("user");
      this.removeFromCartList(this.cartdetail).then((resp)=>{
        window.location.href="/dashboard";
      })
    },
    companydetail() {
      this.comp.user_id_comp = localStorage.getItem("user");
      this.detailUpdate(this.comp).then((res) => {});
    },
  },
};
</script>
<style scoped>
.font-color {
  color: #1e38a7;
}
.bg-color {
  background-color: #1e38a7;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>